import React, { useRef } from "react";
import styles from "./AllSection2.module.css";
import useScrollZoom from "../../../components/useScrollZoom";
import useColorChangeOnScroll from "../../../components/useColorChangeOnScroll";

const AllSection2 = () => {
  const textRef = useRef(null);
  const sectionRef = useScrollZoom();

  useColorChangeOnScroll(sectionRef, textRef);

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={`${styles.text}`}>
          <h3 ref={textRef} className={`${styles.textBody} textBody`}>
            Quambiant Amaranthine is a testament to refined living. Tailored for
            the discerning few, this Suite-Style elegance in Khajaguda offers an
            array of meticulously crafted 3 & 4BHK Apartments & Duplexes. With
            luminous, expansive interiors and thoughtfully designed spaces,
            every corner at Amaranthine reflects a commitment to distinction and
            exclusivity. <br />
            <br /> Welcome to a realm where the enchantment of Suite-Style
            living awaits at every turn.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default AllSection2;
