import React from "react";
import styles from "./Section3.module.css";
import useScrollZoom from "../../../components/useScrollZoom"


import icon1 from "../../../assets/images/icons/swimming pool 1.png";
import icon2 from "../../../assets/images/icons/spa 1.png";
import icon3 from "../../../assets/images/icons/saloon 1.png";
import icon4 from "../../../assets/images/icons/sauna 1.png";
import icon5 from "../../../assets/images/icons/toodler swimming pool 1.png";
import icon6 from "../../../assets/images/icons/badminton 1.png";
import icon7 from "../../../assets/images/icons/home theatre 1.png";
import icon8 from "../../../assets/images/icons/multi purpose hall 1.png";
import icon9 from "../../../assets/images/icons/bar & bar lounge 1.png";
import icon10 from "../../../assets/images/icons/party terrace 1.png";
import icon11 from "../../../assets/images/icons/business centre 1.png";
import icon12 from "../../../assets/images/icons/cafetaria 1.png";
import icon13 from "../../../assets/images/icons/creche 1.png";
import icon14 from "../../../assets/images/icons/clinic 1.png";
import icon15 from "../../../assets/images/icons/indoor kids play area 1.png";
import icon16 from "../../../assets/images/icons/lounge 1.png";
import icon17 from "../../../assets/images/icons/super market 1.png";
import icon18 from "../../../assets/images/icons/table tennis 1.png";
import icon19 from "../../../assets/images/icons/art and craft 1.png";
import icon20 from "../../../assets/images/icons/library 1.png";
import icon21 from "../../../assets/images/icons/super market copy 1.png";
import icon22 from "../../../assets/images/icons/table tennis copy 1.png";
import icon23 from "../../../assets/images/icons/art and craft copy 1.png";
import icon24 from "../../../assets/images/icons/library copy 1.png";
import icon25 from "../../../assets/images/icons/library copy 2 1.png";

const Section3 = () => {
  const sectionRef = useScrollZoom()

  const tiles = [
    { icon: icon1, text: 'Terrace Swimming Pool' },
    { icon: icon2, text: 'Spa' },
    { icon: icon3, text: 'Salon ' },
    { icon: icon4, text: 'Sauna' },
    { icon: icon5, text: 'Toddler Swimming Pool' },
    { icon: icon6, text: '2 Badminton Court' },
    { icon: icon7, text: 'Home Theatre' },
    { icon: icon8, text: 'Multi-Purpose Hall' },
    // { icon: icon9, text: 'Bar & Bar Lounge' },
    // { icon: icon10, text: 'Party Terrace Lounge' },
    { icon: icon11, text: 'Business Centre' },
    { icon: icon12, text: 'Cafeteria' },
    { icon: icon13, text: 'Creche' },
    // { icon: icon14, text: 'Clinic' },
    { icon: icon15, text: 'Indoor Kids Play Area' },
    { icon: icon16, text: 'Lounge' },
    { icon: icon17, text: 'Super Market' },
    { icon: icon18, text: 'Table Tennis' },
    { icon: icon19, text: 'Art & Craft Room' },
    { icon: icon20, text: 'Library' },
    { icon: icon21, text: 'Outdoor Coffee Lounge' },
    // { icon: icon22, text: 'Tennis Courts' },
    { icon: icon23, text: 'Billiards Table' },
    // { icon: icon24, text: 'Chess / Carrom' },
    // { icon: icon25, text: 'Air Hocky' },
  ];
  
  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <h1 className={`${styles.textTitle}`}>AMENITIES</h1>
        <div className={`${styles.tilesContainer}`}>
          {tiles.map((tile, index) => (
            <div className={`${styles.tile}`} key={index}>
              <img
                src={tile.icon}
                alt={`Icon ${index + 1}`}
                className={`${styles.icon}`}
              />
              <p>{tile.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section3;