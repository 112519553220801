import React, { useRef } from "react";
import styles from "./Section2.module.css";
import useScrollZoom from "../../../components/useScrollZoom";
import useColorChangeOnScroll from "../../../components/useColorChangeOnScroll";

const Section2 = () => {
  const sectionRef = useScrollZoom();
  const textRef = useRef(null);

  useColorChangeOnScroll(sectionRef, textRef);

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={`${styles.text}`}>
          <h3 ref={textRef} className={`${styles.textBody} textBody`}>
            At Quambiant, we believe a home is a reflection of dreams, a
            foundation for memories, and a space to grow. Rooted in the
            philosophy of Qualitative Ambiance, we’re dedicated to creating
            vibrant, future-ready communities that blend design, functionality,
            and warmth. <br/><br/>Guided by a passion for exceptional design, we strive to
            create spaces that feel like home and grow in value, offering you a
            lifetime of joy and security. At Quambiant, your dreams shape our
            vision; together, we bring them to life.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Section2;
