// import React from "react";
import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./Section4.module.css";
import slide1 from "../../../assets/newImages/1615.png";
import slide2 from "../../../assets/newImages/2335.png";
import slide3 from "../../../assets/newImages/a3-img1.png";
import slide4 from "../../../assets/newImages/a3-img2.png";
import slide5 from "../../../assets/newImages/a11-img1.png";
import slide6 from "../../../assets/newImages/aa11-img2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faExpand,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const Section4 = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupImage, setPopupImage] = useState("");

  const openPopup = (image) => {
    setPopupImage(image);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setPopupImage("");
    setIsPopupOpen(false);
  };
  const scrollCarousel = (direction) => {
    const carousel = document.querySelector(`.${styles.carousel}`);
    const scrollAmount = carousel.clientWidth; // Amount to scroll based on viewport width
    if (direction === "left") {
      carousel.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    } else if (direction === "right") {
      carousel.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  

  return (
    <div className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        {/* Left Navigation */}
        <button
          className={`${styles.navButton} ${styles.leftButton}`}
          onClick={() => scrollCarousel("left")}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {/* Right Navigation */}
        <button
          className={`${styles.navButton} ${styles.rightButton}`}
          onClick={() => scrollCarousel("right")}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
        <div className={`${styles.carousel}`}>
          {/* Slide 1 */}
          <div className={`${styles.slide}`}>
            <h4 className={`${styles.slideTitle}`}>1615 SQFT - A03, A04</h4>
            <div className={`${styles.imgContainer}`}>
              <img
                className={`${styles.slideImg}`}
                src={slide1}
                alt="1615 SQFT"
              />
              <FontAwesomeIcon
                icon={faExpand}
                className={`${styles.expandIcon}`}
                onClick={() => openPopup(slide1)}
              />
              <span className={`${styles.tooltip}`}>View image in full screen</span>

            </div>
            <div className={`${styles.tableContainer}`} >
              <table className={`${styles.table}`}>
                <thead>
                  <tr>
                    <th>AREA DETAILS</th>
                    <th>AREA IN Sq.ft</th>
                    <th>PERCENTAGE</th>
                    <th>AREA IN Sq.mt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CARPET AREA</td>
                    <td>1039.17</td>
                    <td>64.34</td>
                    <td>96.54</td>
                  </tr>
                  <tr>
                    <td>OUTER WALL AREA</td>
                    <td>72.02</td>
                    <td>4.46</td>
                    <td>6.69</td>
                  </tr>
                  <tr>
                    <td>BALCONY AND UTILITY AREA</td>
                    <td>69.92</td>
                    <td>4.33</td>
                    <td>6.50</td>
                  </tr>
                  <tr>
                    <td>TOTAL BUILT UP AREA</td>
                    <td>1181.11</td>
                    <td>73.13</td>
                    <td>109.73</td>
                  </tr>
                  <tr>
                    <td>COMMON AREA</td>
                    <td>433.89</td>
                    <td>26.87</td>
                    <td>40.31</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>SALEABLE AREA</strong>
                    </td>
                    <td>
                      <strong>1615.00</strong>
                    </td>
                    <td>
                      <strong>100.00</strong>
                    </td>
                    <td>
                      <strong>150.04</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Slide 2 */}
          <div className={`${styles.slide}`}>
            <h4 className={`${styles.slideTitle}`}>2335 SQFT - A10, A11</h4>
            <div className={`${styles.imgContainer}`}>
              <img
                className={`${styles.slideImg}`}
                src={slide2}
                alt="1615 SQFT"
              />
              <FontAwesomeIcon
                icon={faExpand}
                className={`${styles.expandIcon}`}
                onClick={() => openPopup(slide1)}
              />
              <span className={`${styles.tooltip}`}>View image in full screen</span>

            </div>
            <div className={`${styles.tableContainer}`}>
              <table className={`${styles.table}`}>
                <thead>
                  <tr>
                    <th>AREA DETAILS</th>
                    <th>AREA IN Sq.ft</th>
                    <th>PERCENTAGE</th>
                    <th>AREA IN Sq.mt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CARPET AREA</td>
                    <td>1464.69</td>
                    <td>62.73</td>
                    <td>136.07</td>
                  </tr>
                  <tr>
                    <td>OUTER WALL AREA</td>
                    <td>78.98</td>
                    <td>3.38</td>
                    <td>7.34</td>
                  </tr>
                  <tr>
                    <td>BALCONY AND UTILITY AREA</td>
                    <td>169.04</td>
                    <td>7.24</td>
                    <td>15.70</td>
                  </tr>
                  <tr>
                    <td>TOTAL BUILT UP AREA</td>
                    <td>1712.71</td>
                    <td>73.35</td>
                    <td>159.12</td>
                  </tr>
                  <tr>
                    <td>COMMON AREA</td>
                    <td>622.29</td>
                    <td>26.65</td>
                    <td>57.81</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>SALEABLE AREA</strong>
                    </td>
                    <td>
                      <strong>2335.00</strong>
                    </td>
                    <td>
                      <strong>100.00</strong>
                    </td>
                    <td>
                      <strong>216.93</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Slide 3 */}
          <div className={`${styles.slide}`}>
            <h4 className={`${styles.slideTitle}`}>A03 (LOWER DUPLEX PLAN)</h4>
            <div className={`${styles.imgContainer}`}>
              <img
                className={`${styles.slideImg}`}
                src={slide3}
                alt="1615 SQFT"
              />
              <FontAwesomeIcon
                icon={faExpand}
                className={`${styles.expandIcon}`}
                onClick={() => openPopup(slide1)}
              />
              <span className={`${styles.tooltip}`}>View image in full screen</span>

            </div>

            <div className={`${styles.tableContainer}`}>
              <table className={`${styles.table}`}>
                <thead>
                  <tr>
                    <th>AREA DETAILS</th>
                    <th>AREA IN Sq.ft</th>
                    <th>PERCENTAGE</th>
                    <th>AREA IN Sq.mt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CARPET AREA</td>
                    <td>1039.16</td>
                    <td>64.34</td>
                    <td>96.54</td>
                  </tr>
                  <tr>
                    <td>Exclusive Common Area of unit given to Allotee</td>
                    <td>141.95</td>
                    <td>8.79</td>
                    <td>13.19</td>
                  </tr>
                  <tr>
                    <td>Common Area Alloted To Association</td>
                    <td>433.89</td>
                    <td>26.87</td>
                    <td>40.31</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>SALEABLE AREA</strong>
                    </td>
                    <td>
                      <strong>1615.00</strong>
                    </td>
                    <td>
                      <strong>100.00</strong>
                    </td>
                    <td>
                      <strong>150.04</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Slide 4 */}
          <div className={`${styles.slide}`}>
            <h4 className={`${styles.slideTitle}`}>A03 (UPPER DUPLEX PLAN)</h4>
            <div className={`${styles.imgContainer}`}>
              <img
                className={`${styles.slideImg}`}
                src={slide4}
                alt="1615 SQFT"
              />
              <FontAwesomeIcon
                icon={faExpand}
                className={`${styles.expandIcon}`}
                onClick={() => openPopup(slide1)}
              />
              <span className={`${styles.tooltip}`}>View image in full screen</span>

            </div>

            <div className={`${styles.tableContainer}`}>
              <table className={`${styles.table}`}>
                <thead>
                  <tr>
                    <th>AREA DETAILS</th>
                    <th>AREA IN Sq.ft</th>
                    <th>PERCENTAGE</th>
                    <th>AREA IN Sq.mt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CARPET AREA</td>
                    <td>862.48</td>
                    <td>53.40</td>
                    <td>80.13</td>
                  </tr>
                  <tr>
                    <td>Exclusive Common Area of unit given to Allotee</td>
                    <td>318.63</td>
                    <td>19.73</td>
                    <td>29.60</td>
                  </tr>
                  <tr>
                    <td>Common Area Alloted To Association</td>
                    <td>433.89</td>
                    <td>26.87</td>
                    <td>40.31</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>SALEABLE AREA</strong>
                    </td>
                    <td>
                      <strong>1615.00</strong>
                    </td>
                    <td>
                      <strong>100.00</strong>
                    </td>
                    <td>
                      <strong>150.04</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Slide 5 */}
          <div className={`${styles.slide}`}>
            <h4 className={`${styles.slideTitle}`}>A11 (LOWER DUPLEX PLAN)</h4>
            <div className={`${styles.imgContainer}`}>
              <img
                className={`${styles.slideImg}`}
                src={slide5}
                alt="1615 SQFT"
              />
              <FontAwesomeIcon
                icon={faExpand}
                className={`${styles.expandIcon}`}
                onClick={() => openPopup(slide1)}
              />
              <span className={`${styles.tooltip}`}>View image in full screen</span>

            </div>
            <div className={`${styles.tableContainer}`}>
              <table className={`${styles.table}`}>
                <thead>
                  <tr>
                    <th>AREA DETAILS</th>
                    <th>AREA IN Sq.ft</th>
                    <th>PERCENTAGE</th>
                    <th>AREA IN Sq.mt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CARPET AREA</td>
                    <td>1417.68</td>
                    <td>60.71</td>
                    <td>131.71</td>
                  </tr>
                  <tr>
                    <td>Exclusive Common Area of unit given to Allotee</td>
                    <td>295.03</td>
                    <td>12.64</td>
                    <td>27.41</td>
                  </tr>
                  <tr>
                    <td>Common Area Alloted To Association</td>
                    <td>622.29</td>
                    <td>26.65</td>
                    <td>57.81</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>SALEABLE AREA</strong>
                    </td>
                    <td>
                      <strong>2335.00</strong>
                    </td>
                    <td>
                      <strong>100.00</strong>
                    </td>
                    <td>
                      <strong>216.93</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Slide 6 */}
          <div className={`${styles.slide}`}>
            <h4 className={`${styles.slideTitle}`}>A11 (UPPER DUPLEX PLAN)</h4>
            <div className={`${styles.imgContainer}`}>
              <img
                className={`${styles.slideImg}`}
                src={slide6}
                alt="1615 SQFT"
              />
              <FontAwesomeIcon
                icon={faExpand}
                className={`${styles.expandIcon}`}
                onClick={() => openPopup(slide1)}
              />
              <span className={`${styles.tooltip}`}>View image in full screen</span>
            </div>
            <div className={`${styles.tableContainer}`}>
              <table className={`${styles.table}`}>
                <thead>
                  <tr>
                    <th>AREA DETAILS</th>
                    <th>AREA IN Sq.ft</th>
                    <th>PERCENTAGE</th>
                    <th>AREA IN Sq.mt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CARPET AREA</td>
                    <td>1081.22</td>
                    <td>46.30</td>
                    <td>100.45</td>
                  </tr>
                  <tr>
                    <td>Exclusive Common Area of unit given to Allotee</td>
                    <td>631.49</td>
                    <td>27.04</td>
                    <td>58.67</td>
                  </tr>
                  <tr>
                    <td>Common Area Alloted To Association</td>
                    <td>622.29</td>
                    <td>26.65</td>
                    <td>57.81</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>SALEABLE AREA</strong>
                    </td>
                    <td>
                      <strong>2335.00</strong>
                    </td>
                    <td>
                      <strong>100.00</strong>
                    </td>
                    <td>
                      <strong>216.93</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <div className={`${styles.popup}`}>
          <div className={`${styles.popupOverlay}`} onClick={closePopup}></div>
          <div className={`${styles.popupContent}`}>
            <FontAwesomeIcon
              icon={faTimes}
              className={`${styles.closeIcon}`}
              onClick={closePopup}
            />
            <img
              src={popupImage}
              alt="Popup View"
              className={`${styles.popupImg}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Section4;
