import React, { useRef, useEffect } from "react";
import styles from "./Section12.module.css";
import amaranthinVideo from "../../../assets/videos/New Amaranthine Walkthought.mp4";
import thumbnail from "../../../assets/images/thumbnail.png";

const Section12 = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Only pause the video if it's not intersecting
        if (!entries[0].isIntersecting) {
          videoRef.current.pause();
        }
      },
      {
        threshold: 0.25, // Trigger if less than 25% of the video is visible
      }
    );

    // Start observing the video element
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.section + " section "}>
      <div className={styles.overlay}>
        <div className={styles.videoContainer}>
          <video
            ref={videoRef}
            width="100%"
            height="100%"
            controls
            title="Amaranthine Walkthrough"
            poster={thumbnail}
            frameBorder="0"
            allowFullScreen
          >
            <source src={amaranthinVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Section12;
