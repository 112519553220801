// import React from "react";
import styles from "./Section11.module.css";
import image1 from "../../../assets/images/bacgrounds/long1.jpg";
import image3 from "../../../assets/images/bacgrounds/long2.jpg";
import image2 from "../../../assets/images/bacgrounds/long3.jpg";
import MobVersion from "../../../components/mobileNewSection11/index.jsx"

const Section11 = () => {
  return (
    <div className={`${styles.sectionContainer} container-fluid section`}>
      <h3 className={`${styles.title} heading`}>PROJECT DETAILS</h3>
      <div className={`${styles.desktopMode} row`}>
        <div className={`col-5 ${styles.textContainer}`}>
          <div className={`${styles.desc}`}>
            <h2>42</h2>
            <p>Floor</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>502</h2>
            <p>Units</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>2.98 Acre</h2>
            <p>Plot Size</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>38,000 sqft</h2>
            <p>Club House</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>Upto 240 sqft</h2>
            <p>Balconies</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>3 & 4 BHK Apartments &</h2>
            <p>Duplexes</p>
          </div>
          {/* <div className={`${styles.desc}`}>
            <h2>2/3/4/5</h2>
            <p>Bathrooms</p>
          </div> */}
          <div className={`${styles.desc}`}>
            <h2>100%</h2>
            <p>Vastu Compliant</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>1615 - 5700 Sq Ft</h2>
            <p>Super built-up area</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>December 2026</h2>
            <p>Possession</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>5 Level</h2>
            <p>Car Parking</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>1820, 2250, 2850 Sq Ft </h2>
            <p>West (SOLD OUT)</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>2850, 2020, 2450 Sq Ft </h2>
            <p>East (SOLD OUT)</p>
          </div>

        </div>

        <div className={` ${styles.imageContainer} col-7 `}></div>
      </div>
      <div className={`${styles.mobileMode} `}>
        <MobVersion/>
      </div>
    </div>
  );
};

export default Section11;
