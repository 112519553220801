import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import ContactPopup from "../contactPopup/ContactPopup"; // Update with the correct path

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [action, setAction] = useState(null); // Stores the action to be taken after popup submission

  const location = useLocation();
  const navigate = useNavigate();

  // Handle scroll visibility
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const handleScroll = () => {
    let currentScrollTop = window.scrollY;
    setIsVisible(currentScrollTop < lastScrollTop);
    setLastScrollTop(currentScrollTop);
  };

  // Toggle mobile menu
  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
    if (window.innerWidth <= 768) {
      document.body.style.overflow = isOpen ? "auto" : "hidden";
    }
  };

  // Toggle ContactPopup
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Handle Drone View button click
  const handleDroneViewClick = () => {
    if (localStorage.getItem("formSubmittedForDroneView")) {
      navigate("/droneview");
    } else {
      setAction("droneview"); // Set the action to droneview
      setIsPopupOpen(true); // Open the popup
    }
    setIsOpen(false); // Close the menu
    document.body.style.overflow = "auto"; // Restore scrolling
  };

  // Handle successful form submission in the popup
  const handleFormSubmitSuccess = () => {
    if (action === "droneview") {
      localStorage.setItem("formSubmittedForDroneView", "true");
      setIsPopupOpen(false); // Close the popup
      navigate("/droneview"); // Navigate to Drone View
    }
  };

  return (
    <div className={`mainParent ${isVisible ? "" : styles.hidden}`}>
      <header className={`${styles.header} row px-lg-5`}>
        <div
          className={`${styles.mobileHeader} col-sm-12 col-12 col-md-3 col-lg-3 px-4`}
        >
          <a href="/" className={styles.Amaranthine}>
            Amaranthine
          </a>
          <button className={`${styles.button}`} onClick={toggleMenu}>
            {isOpen ? "✕" : "☰"}
          </button>
        </div>
        <div className={styles.headerDesktop + " col-md-8 col-lg-8"}>
          <nav className={`${styles.nav} ${isOpen ? styles.open : ""}`}>
            <NavButton
              to="/"
              label="Home"
              isActive={location.pathname === "/"}
            />
            <NavButton
              to="/Amenities"
              label="Amenities"
              isActive={location.pathname === "/Amenities"}
            />
            <NavButton
              to="/ProjectDetails"
              label="Project Details"
              isActive={location.pathname === "/ProjectDetails"}
            />
            <NavButton
              to="/Plans"
              label="Plans"
              isActive={location.pathname === "/Plans"}
            />
            <NavButton
              to="/AboutUs"
              label="About Us"
              isActive={location.pathname === "/AboutUs"}
            />
            <NavButton
              to="/ContactUs"
              label="Contact Us"
              isActive={location.pathname === "/ContactUs"}
            />
            <NavButton label="Drone View" onClick={handleDroneViewClick} />
          </nav>
        </div>
      </header>
      <ContactPopup
        isOpen={isPopupOpen}
        togglePopup={togglePopup}
        onSubmitSuccess={handleFormSubmitSuccess} // Pass the handler for successful form submission
      />
    </div>
  );
};

// NavButton Component
const NavButton = ({ to, label, isActive, onClick }) => {
  return (
    <a
      href={to || "#"}
      className={`${styles.link} ${isActive ? styles.active : ""}`}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault(); // Prevent default navigation behavior
          onClick();
        }
      }}
    >
      {label}
    </a>
  );
};

export default Header;
